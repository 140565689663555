{
    class ProjectService {

        static get $inject() {
            return ['aerosApi'];
        }

        constructor(aerosApi) {
            this.aerosApi = aerosApi;
        }

        setProject = project => {
            this.project = project;
        };

        fetchProject = (projectId, orgId) =>
            this.aerosApi.loadProject(projectId, orgId)
                .then((res) => {
                    this.setProject(res.data);
                    return res;
                });

    }

    angular.module('aerosApp')
        .service('ProjectService', ProjectService);
}
